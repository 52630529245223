export const isImg200 = async (
  src: string,
  defaultImage?: any,
  isArray?: boolean,
): Promise<string | null> => {
  try {
    const response = await useFetch(src, {
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      credentials: 'same-origin',
    })

    if (response.status.value !== 'success') return defaultImage
    return src
  } catch (error) {
    console.log(error)
  }
  if (isArray) {
    return null
  } else {
    return defaultImage
  }
}
