import { isImg200 } from '~/helpers/photos/isImg200'

export const deleteUnavaliable = async (arr: []) => {
  const filteredData = arr.map(async (item: { photos: [{ url: string }] }) => {
    const filteredPhotos = await Promise.all(
      item.photos.map(async (img) => {
        const isAvaliable = await isImg200(img.url)
        return { img, isAvaliable }
      }),
    )
    const filteredItem = {
      ...item,
      photos: filteredPhotos.filter((photo) => photo.isAvaliable),
    }
    return filteredItem
  })
  let filtered: any
  await Promise.all(filteredData).then((result) => {
    filtered = result
  })

  return filtered
}

