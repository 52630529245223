import avito from '~/assets/img/ParserResources/avito.svg'
import cian from '~/assets/img/ParserResources/cian.svg'
import domclick from '~/assets/img/ParserResources/domclick.svg'
import yandex from '~/assets/img/ParserResources/yandex.svg'

const map = [
  {
    name: 'avito',
    file: avito,
  },
  {
    name: 'cian',
    file: cian,
  },
  {
    name: 'domclick',
    file: domclick,
  },
  {
    name: 'yandex',
    file: yandex,
  },
]

export const getUserAvatar = (item: {
  source: string
  ad_source: string
  agent: { avatar: string }
}) => {
  const onError = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
  if (item.source === 'parsed') {
    const matchingObject = map.find((q) => {
      return item.ad_source.includes(q.name)
    })
    return matchingObject ? matchingObject.file : onError
  } else if (item.agent) {
    return item.agent.avatar ? item.agent.avatar : onError
  }
}
